import { Inject, inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
  CommerceOptionsFormDataModel,
  ExternalPostData,
  FindPremiumAdReqPayload,
  PurchaseUrlWithAmountWithCurrencyReqPayload,
  ShoppingCartModel
} from '@models/commerce-options-form-data.model';
import { PremiumAdModel, PremiumAdStatusEnum } from '@models/premium-ad.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {
  #httpClient = inject(HttpClient);
  #baseUrl = `${environment.apiUrl}/commerce`;

  document = inject(DOCUMENT);

  getCommerceOptions(): Observable<CommerceOptionsFormDataModel> {
    const url = `${this.#baseUrl}/options`;

    return this.#httpClient
      .get<CommerceOptionsFormDataModel>(url)
      .pipe(map((json: any) => CommerceOptionsFormDataModel.fromJson(json)));
  }

  getShoppingCart(accountId: number): Observable<ShoppingCartModel> {
    const url = `${this.#baseUrl}/cart/${accountId}`;
    return this.#httpClient
      .get(url)
      .pipe(map((res) => ShoppingCartModel.fromJson(res)));
  }

  findPremiumAdList({
    accountId,
    adIds,
    status
  }: FindPremiumAdReqPayload): Observable<PremiumAdModel[]> {
    const url = `${this.#baseUrl}/premium-ad/_bulk`;
    const body = {
      account_id: accountId,
      ad_ids: adIds,
      status: status
    };

    return this.#httpClient
      .post<PremiumAdModel[]>(url, body)
      .pipe(map((list) => list.map((item) => PremiumAdModel.fromJson(item))));
  }

  getPremiumAdById(id: number): Observable<PremiumAdModel> {
    const url = `${this.#baseUrl}/premium-ad/${id}`;

    return this.#httpClient
      .get<PremiumAdModel>(url)
      .pipe(map((json: any) => PremiumAdModel.fromJson(json)));
  }

  generateBitCoinUrl(): Observable<{ payment_url: string }> {
    const url = `${this.#baseUrl}/bitcoin/payment-url`;
    return this.#httpClient.get<{ payment_url: string }>(url);
  }

  getPurchaseUrlWithAmountWithCurrency(
    payload: PurchaseUrlWithAmountWithCurrencyReqPayload
  ): Observable<{ payment_url: string }> {
    const url = `${this.#baseUrl}${payload.paymentUrl}`;
    let params = new HttpParams().set(
      'account_id',
      payload.accountId.toString()
    );

    if (payload.amount) {
      params = params.set('amount', payload.amount);
    }

    if (payload.currency) {
      params = params.set('currency', payload.currency);
    }

    return this.#httpClient.get<{ payment_url: string }>(url, { params });
  }

  postToExternalSite(externalPostData: ExternalPostData): void {
    const form = this.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', externalPostData.actionUrl);

    const attrs = Object.assign({}, externalPostData.attrs);

    Object.keys(attrs).forEach((key) => {
      form.appendChild(this.createHiddenInputElement(key, attrs[key]));
    });

    this.document.body.appendChild(form);
    form.submit();
  }

  private createHiddenInputElement(
    name: string,
    value: string
  ): HTMLInputElement {
    const hiddenField = this.document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }
}
