import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, of, throwError } from 'rxjs';
import { environment } from '@env/environment';
import {
  ProfileFormDataModel,
  ProfileStyleModel
} from '@models/profile-form-data.model';
import { PersonModel } from '@models/person.model';
import {
  ICreateDocumentReqBody,
  ICreateDocumentResBody,
  ICreateProfileReqBody,
  IProfileToJson,
  ProfileModel,
  ProfilePhotoEnum,
  ProfilePhotoModel,
  ProfileRevisionStatusEnum
} from '@models/profile.model';
import { NetVerifiedCountriesModel } from '@models/net-verified-countries.model';
import { AuthService } from '@services/auth.service';
import { DocumentModel, DocumentStatusEnum } from '@models/document.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EscortProfileService {
  #httpClient = inject(HttpClient);
  #baseUrl = `${environment.apiUrl}/escort-profile`;
  netVerifiedCountries$: Observable<NetVerifiedCountriesModel[]>;
  #authService = inject(AuthService);

  getProfileFormData(): Observable<ProfileFormDataModel> {
    const url = `${this.#baseUrl}/form-data`;

    return this.#httpClient
      .get<ProfileFormDataModel>(url)
      .pipe(map((res) => res))
      .pipe(map((json: any) => ProfileFormDataModel.fromJson(json)));
  }

  getPersons(accountId: number) {
    const url = `${this.#baseUrl}/persons`;
    const params = new HttpParams().set('account_id', accountId.toString());

    return this.#httpClient
      .get(url, { params })
      .pipe(
        map((json: any[]) =>
          json
            .map((item) => PersonModel.fromJson(item))
            .filter((person) => !person.isDeactivated)
        )
      );
  }

  getProfileById(id: string | number): Observable<ProfileModel> {
    const url = `${this.#baseUrl}/${id}`;

    return this.#httpClient
      .get(url)
      .pipe(map((json) => ProfileModel.fromJson(json)));
  }

  getAllServiceAds(accountId: number): Observable<ProfileModel[]> {
    const params = new HttpParams().set('account_id', accountId.toString());
    const url = `${this.#baseUrl}`;

    return this.#httpClient
      .get<ProfileModel[]>(url, { params })
      .pipe(map((json) => json.map((item) => ProfileModel.fromJson(item))));
  }

  getLastRev(profileId: any): Observable<ProfileModel> {
    const url = `${this.#baseUrl}/rev/latest/?profile_id=${profileId}`;
    return this.#httpClient
      .get(url)
      .pipe(map((json: any) => ProfileModel.fromJson(json)));
  }

  getNetVerifyCountries(): Observable<NetVerifiedCountriesModel[]> {
    if (this.netVerifiedCountries$) {
      return this.netVerifiedCountries$;
    }

    const url = `${environment.apiUrl}/profile/document/shuftipro/countries`;
    return (this.netVerifiedCountries$ = this.#httpClient.get(url).pipe(
      map((res: []) =>
        res
          .map((item) => NetVerifiedCountriesModel.fromJson(item))
          .sort((a, b) => {
            return a.countryName < b.countryName
              ? -1
              : a.countryName > b.countryName
                ? 1
                : 0;
          })
      )
    ));
  }

  getDocuments(): Observable<Array<DocumentModel>> {
    const url = `${this.#baseUrl}/documents/?account_id=${this.#authService.decodedUserAccount.id}`;
    return this.#httpClient
      .get(url)
      .pipe(map((json: any[]) => json.map((d) => DocumentModel.fromJson(d))));
  }

  getStyleList(): Observable<ProfileStyleModel[]> {
    const url = `${this.#baseUrl}/styles`;
    return this.#httpClient
      .get(url)
      .pipe(
        map((json: Array<any>) =>
          json.map((style) => ProfileStyleModel.fromJson(style))
        )
      );
  }

  getAllPhotos(photo_ids: string[], accountId: number) {
    const url = `${this.#baseUrl}/photos/_bulk`;

    const body = {
      account_id: accountId,
      photo_ids: photo_ids
    };

    return this.#httpClient
      .post(url, body)
      .pipe(
        map((json: any[]) =>
          json.map((item) => ProfilePhotoModel.fromJson(item))
        )
      );
  }

  createPerson(name: string): Observable<{ id: string }> {
    const url = `${this.#baseUrl}/persons`;
    const body = { name, account_id: this.#authService.decodedUserAccount.id };

    return this.#httpClient.post<{ id: string }>(url, body);
  }

  getPersonById(personId: string): Observable<PersonModel> {
    const url = `${this.#baseUrl}/person/${personId}`;

    return this.#httpClient
      .get(url)
      .pipe(map((json) => PersonModel.fromJson(json)));
  }

  createProfile(data: ICreateProfileReqBody): Observable<{ id: string }> {
    const url = `${this.#baseUrl}`;
    const { personId, isMulti, isFast } = data;

    const body = {
      account_id: this.#authService.decodedUserAccount.id,
      person_id: isMulti ? null : personId,
      is_multi: isMulti,
      is_fast: isFast
    };

    return this.#httpClient.post<{ id: string }>(url, body);
  }

  getAllProfileRev(profileIds: string[]): Observable<ProfileModel[]> {
    const url = `${this.#baseUrl}/revs/latest/_bulk`;

    const body = {
      account_id: this.#authService.decodedUserAccount.id,
      profile_ids: profileIds
    };
    return this.#httpClient
      .post<ProfileModel[]>(url, body)
      .pipe(
        map((json: ProfileModel[]) =>
          json.map((item) => ProfileModel.fromJson(item))
        )
      );
  }

  createPhotos(
    imageIds: string[],
    accountId: number,
    personId: string
  ): Observable<ProfilePhotoModel[]> {
    const url = `${this.#baseUrl}/photos`;
    const body = {
      image_ids: imageIds,
      account_id: accountId,
      person_id: personId
    };
    if (!personId) delete body.person_id;

    return this.#httpClient
      .post<{ photo_id: string; image_id: string; number: number }[]>(url, body)
      .pipe(
        map((res) =>
          res.map((item) => ({
            id: item.photo_id,
            imageId: item.image_id,
            status: ProfilePhotoEnum.Pending,
            number: item.number,
            personId: personId,
            dateCreated: new Date()
          }))
        )
      );
  }

  deleteProfilePhoto(photoId: string): Observable<unknown> {
    const url = `${this.#baseUrl}/photos/delete-storage/${photoId}`;
    return this.#httpClient.delete(url);
  }

  putProfileRevision(
    rev: Partial<IProfileToJson>,
    authUserId: number
  ): Observable<ProfileModel> {
    const url = `${this.#baseUrl}/rev`;
    const body = rev;
    body.account_id = authUserId;
    return this.#httpClient.put(url, body).pipe(
      map((revision) => {
        return ProfileModel.fromJson({
          ...rev,
          ...revision
        });
      })
    );
  }

  createRevision(revision: ProfileModel): Observable<ProfileModel> {
    const url = `${this.#baseUrl}/rev`;
    const json = {
      ...ProfileModel.toJson(revision)
    };

    return this.#httpClient.put<ProfileModel>(url, json).pipe(
      map(({ id }) => ({
        ...revision,
        id,
        revisionMeta: {
          status: ProfileRevisionStatusEnum.Pending,
          number: 1,
          createdBy: this.#authService.decodedUserAccount.id,
          processedBy: null,
          modifiedBy: null,
          isModifiable: true,
          isApprovable: false,
          isDeleted: false,
          showName: '',
          dateCreated: new Date(),
          isVisiting: revision.isVisiting,
          profileId: revision.profileId,
          dateModified: null
        }
      }))
    );
  }

  createDocuments(
    data: ICreateDocumentReqBody[],
    accountId: number
  ): Observable<DocumentModel[]> {
    const url = `${this.#baseUrl}/documents/_bulk`;
    const body: any = { docs: data };
    return this.#httpClient.post<ICreateDocumentResBody[]>(url, body).pipe(
      map((relations) =>
        relations.map(
          ({ id, person_id, type, documents }) =>
            ({
              id: id,
              accountId,
              createdBy: accountId,
              dateCreated: new Date(),
              documents: documents,
              isDeleted: false,
              isAcceptable: true,
              personId: person_id,
              status: DocumentStatusEnum.Pending,
              type: type
            }) as DocumentModel
        )
      ),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }
}
