export enum DocumentNotificationStatusEnum {
  Approved = 'approved',
  NotVerified = 'not_verified',
  Declined = 'declined',
  Pending = 'pending',
  Expired = 'expired'
}

export class DocumentModel {
  accountId: number;
  createdBy: number;
  dateCreated: Date;
  documents: { [key: string]: string }[];
  isDeleted: boolean;
  personId: string;
  status: DocumentStatusEnum;
  id: string;
  type: DocumentTypeEnum;
  birthDate?: string;
  idNumber?: string;
  isAcceptable: boolean;

  static fromJson(json: any): DocumentModel {
    return {
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: json.date_created,
      documents: json.documents,
      isDeleted: json.is_deleted,
      isAcceptable: json.is_acceptable,
      personId: json.person_id,
      status: json.status,
      id: json._id,
      type: json.type
    };
  }
}

export enum DocumentStatusEnum {
  Approved = 'approved',
  Pending = 'pending'
}

export enum DocumentTypeEnum {
  Id = 'id',
  AuthenticationPhoto = 'authentication photo',
  UnalteredPhoto = 'unaltered photo'
}
